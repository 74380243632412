import React from "react"
import styled from "styled-components"

const HighlightBox = styled.span`
  display: inline-block;
  position: relative;
`

interface Props {
  size?: "small"
  children?: React.ReactChild
}

const HighlightBackground = styled.span<Props>`
  position: absolute;
  left: 4%;
  top: ${props => (props.size === "small" ? "70%" : "50%")};
  right: -1%;
  bottom: auto;
  height: ${props => (props.size === "small" ? "15%" : "35%")};
  background-color: ${props => props.theme.colors.highlight};

  z-index: 1;
`

const PositionedSpan = styled.span`
  position: relative;
  z-index: 2;
`

const Highlight = (props: Props): React.ReactElement => {
  return (
    <HighlightBox>
      <HighlightBackground size={props.size} />
      <PositionedSpan>{props.children}</PositionedSpan>
    </HighlightBox>
  )
}

export default Highlight
