import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useIsWebpSupported from "./common/is-webp-supported"

function AnonymousLoginImage(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "anon_login_240x480-min.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fallbackImage: file(relativePath: { eq: "anon_login_240x480-min.png" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const isWebpSupported = useIsWebpSupported()
  return (
    <Img
      style={{ borderRadius: 6 }}
      fluid={
        isWebpSupported === false
          ? data.fallbackImage.childImageSharp.fluid
          : data.placeholderImage.childImageSharp.fluid
      }
    />
  )
}

export default AnonymousLoginImage
