import React, { useEffect, useState, SyntheticEvent } from "react"

import styled, { ThemeContext } from "styled-components"

import SendIconURI from "../../images/send-24px-white.svg"
import SimpleSpinner from "./simple-spinner"
import ScreenWidthMediaQueries from "./screen-width-media-queries"
import Highlight from "./highlight"

const FormBlock = styled.section`
  @media ${ScreenWidthMediaQueries.laptop} {
    width: 800px;
    border-radius: 6px;
  }
`

const TextInput = styled(props => <input {...props} />)`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 12px;
  position: relative;
  z-index: 5;
  display: block;
`

const TextArea = styled(props => <textarea {...props} />)`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
  border-radius: 6px;
  border: none;
  padding: 12px;
  min-height: 400px;
  position: relative;
  z-index: 5;
`

const InputLabel = styled(props => <label {...props} />)`
  display: block;
  width: 100%;
`

const InputItem = styled.div`
  padding: 8px 0;
`

const ContactRow = styled.div`
  display: flex;
  justify-content: center;
`

const ContactLink = styled(props => <button {...props} />)`
  margin-top: 8px;
  display: flex;
  align-items: center;

  padding: 16px 28px;
  border-radius: 6px;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.headerBackground};
  cursor: pointer;
  box-shadow: 12px 12px 5px ${props => props.theme.colors.backgroundDark + "80"};
  & :hover {
    background-color: ${props => props.theme.colors.highlight};
  }
  & > h3 {
    margin-bottom: 0;
  }
  & > img {
    width: 16px;
    margin-left: 12px;
  }
`

const ErrorBlock = styled.div`
  background-color: #6b0000;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;
`

const formEncode = (data: { [key: string]: string }) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const MIN_TEXTAREA_LENGTH = 30
const MAX_TEXTAREA_LENGTH = 10000
const MAX_INPUT_LENGTH = 100

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function isEmailValid(email: string): boolean {
  return EMAIL_REGEX.test(email)
}

function isMessageValid(message: string): boolean {
  return (
    MIN_TEXTAREA_LENGTH < message.length && message.length < MAX_TEXTAREA_LENGTH
  )
}

const ErrorText = styled.span`
  color: ${props => props.theme.colors.errorInput};
`

function ContactBlock(): React.ReactElement {
  const [email, setEmail] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const [emailError, setEmailError] = useState<boolean>()
  const [messageError, setMessageError] = useState<boolean>()

  const [isSending, setIsSending] = useState<boolean>(false)

  const [sendSuccess, setIsSendSuccess] = useState<boolean>(false)
  const [sendError, setSendError] = useState<Error>()

  const form = (
    <form name="contact" method="post" data-netlify>
      <InputItem>
        <InputLabel htmlFor="email">
          Email * &nbsp;
          {emailError ? (
            <ErrorText>This is an invalid email :(</ErrorText>
          ) : null}
        </InputLabel>
        <TextInput
          id="email"
          value={email}
          type="email"
          name="email"
          error={emailError}
          maxLength={MAX_INPUT_LENGTH}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value
            if (emailError) {
              setEmailError(!isEmailValid(value.trim()))
            }
            setEmail(value)
          }}
        ></TextInput>
      </InputItem>
      <InputItem>
        <InputLabel htmlFor="name">Name</InputLabel>
        <TextInput
          id="name"
          name="name"
          value={name}
          type="text"
          maxLength={MAX_INPUT_LENGTH}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            setName(event.currentTarget.value)
          }}
        ></TextInput>
      </InputItem>
      <InputItem>
        <InputLabel htmlFor="message">
          Message * &nbsp;
          {messageError ? (
            <ErrorText>
              Please let me know more about your cool project
            </ErrorText>
          ) : null}
        </InputLabel>
        <TextArea
          id="message"
          name="message"
          value={message}
          error={messageError}
          minLength={MIN_TEXTAREA_LENGTH}
          maxLength={MAX_TEXTAREA_LENGTH}
          onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
            const value = event.currentTarget.value
            if (messageError) {
              setMessageError(!isMessageValid(value.trim()))
            }
            setMessage(value)
          }}
        ></TextArea>
      </InputItem>
      {sendError ? (
        <ErrorBlock>
          Sorry something went wrong. Please send me an email to
          mduppes@gmail.com if this doesn't work.
        </ErrorBlock>
      ) : null}
      <ContactRow>
        <ContactLink
          disabled={isSending}
          onClick={(event: SyntheticEvent) => {
            event.preventDefault()

            const emailTrimmed = email.trim()
            if (!isEmailValid(emailTrimmed)) {
              setEmailError(true)
              return
            } else {
              setEmailError(false)
            }

            const messageTrimmed = message.trim()
            if (!isMessageValid(messageTrimmed)) {
              setMessageError(true)
              return
            } else {
              setMessageError(false)
            }

            const nameTrimmed = name.trim()

            setIsSending(true)
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: formEncode({
                "form-name": "contact",
                email: emailTrimmed,
                name: nameTrimmed,
                message: messageTrimmed,
              }),
            })
              .then(() => {
                setIsSending(false)
                setSendError(undefined)
                setIsSendSuccess(true)
              })
              .catch(error => {
                setIsSending(false)
                setSendError(error)
                console.error(error)
              })
          }}
        >
          {isSending ? (
            <SimpleSpinner size={20} />
          ) : (
            <>
              Lets Work Together <img src={SendIconURI} />
            </>
          )}
        </ContactLink>
      </ContactRow>
    </form>
  )

  return (
    <FormBlock>
      <h1 id="get_in_touch">Get in Touch!</h1>
      {sendSuccess ? (
        <p>
          Thanks for reaching out! Let me get back to you as soon as possible :)
        </p>
      ) : (
        <>
          <p>
            <Highlight>Special Offer</Highlight> to build you a website for{" "}
            <Highlight>free</Highlight>. Note this doesn't include hosting or
            custom domains you may want to use. All I ask for in return is to be
            able to feature your site as a testimonial.
          </p>
          <p>
            Let me know about your business, what your budget is, and the idea
            you have in mind. I'm always looking to work with great clients :)
          </p>
          {form}
        </>
      )}
    </FormBlock>
  )
}

export default ContactBlock
