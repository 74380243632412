export const ScreenWidths = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const ScreenWidthMediaQueries = {
  mobileS: `(min-width: ${ScreenWidths.mobileS})`,
  mobileM: `(min-width: ${ScreenWidths.mobileM})`,
  mobileL: `(min-width: ${ScreenWidths.mobileL})`,
  tablet: `(min-width: ${ScreenWidths.tablet})`,
  laptop: `(min-width: ${ScreenWidths.laptop})`,
  laptopL: `(min-width: ${ScreenWidths.laptopL})`,
  desktop: `(min-width: ${ScreenWidths.desktop})`,
  desktopL: `(min-width: ${ScreenWidths.desktop})`,
}

export default ScreenWidthMediaQueries
