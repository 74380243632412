import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import useIsWebpSupported from "./common/is-webp-supported"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface Props {
  overlayColor: string
}

const BackgroundImage = ({ overlayColor }: Props): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "blog_background.webp" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fallbackImage: file(relativePath: { eq: "blog_background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const isWebpSupported = useIsWebpSupported()

  const style = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    //clipPath: "polygon(0 0%, 100% 0%, 100% 100%, 0% 90%)",
  }

  const overlayStyle = Object.assign({}, style, {
    //background: overlayColor,
    background: "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.4))",
    backgroundSize: "cover",
    zIndex: 2,
  })

  return (
    <>
      <div style={overlayStyle} />
      <Image
        fluid={
          isWebpSupported === false
            ? data.fallbackImage.childImageSharp.fluid
            : data.placeholderImage.childImageSharp.fluid
        }
        alt="background photo of myself"
        style={style}
        imgStyle={{ objectPosition: "0% 50%" }}
      />
    </>
  )
}

export default BackgroundImage
