import React, { useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Highlight from "../components/common/highlight"
import BodyContainer from "../components/common/body-container"

import styled, { ThemeContext } from "styled-components"
import BackgroundImage from "../components/background-image"
import ScreenWidthMediaQueries from "../components/common/screen-width-media-queries"
import SkillBlock from "../components/skill-block"
import MyWorkSection from "../components/my-work-section"
import ColumnLayout, { Column } from "../components/common/column-layout"

import TrendingUpIconURI from "../images/trending_up-black-24dp.svg"
import BuildIconURI from "../images/construction-24px.svg"
import GroupAddIconURI from "../images/group_add-24px.svg"
import MobileFriendlyIconURI from "../images/mobile_friendly-24px.svg"
import ContactBlock from "../components/common/contact-block"

const imageHeight = 300
const ImageSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${imageHeight}px;
  @media ${ScreenWidthMediaQueries.tablet} {
    height: ${imageHeight + 300}px;
  }
`

const ImageSectionOffset = styled.div`
  height: ${imageHeight}px;

  @media ${ScreenWidthMediaQueries.tablet} {
    height: ${imageHeight}px;
  }
`
//  maxwidth: 2800px;

const ImageContent = styled.div`
  z-index: 3;
  position: relative;
  display: flex;

  @media ${ScreenWidthMediaQueries.tablet} {
    justify-content: flex-end;
  }
`

const ContentCard = styled.div<{ dark?: boolean }>`
  position: relative;

  @media ${ScreenWidthMediaQueries.tablet} {
    padding: 24px;
    max-width: 600px;
    border-radius: 16px;
    background-color: ${props =>
      props.dark
        ? props.theme.colors.backgroundDark
        : props.theme.colors.backgroundLight};
    box-shadow: 12px 12px 5px
      ${props =>
        props.dark
          ? props.theme.colors.backgroundDark + "80"
          : props.theme.colors.backgroundLight + "80"};
  }
`

interface IconContainerProps {
  children: React.ReactChild
  size: number
}

function IconContainer({ children, size }: IconContainerProps) {
  const themeContext = useContext(ThemeContext)

  return (
    <div
      style={{
        color: themeContext.colors.headerBackground,
        marginRight: 20,
        width: size,
        height: size,
        textAlign: "center",
      }}
    >
      {children}
    </div>
  )
}

const PitchItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.45rem;
`

const PitchText = styled.h2`
  flex: 1 0 0;
  margin-bottom: 0;
`

export const SkillBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
`

interface SpacerProps {
  disappears?: boolean
  small?: boolean
}

export const Spacer = styled.div<SpacerProps>`
  height: ${props => (props.disappears ? 0 : props.small ? "1.4rem" : "3rem")};
  @media ${ScreenWidthMediaQueries.tablet} {
    height: 70px;
  }
`

export const SpacerOnSmall = styled.div<SpacerProps>`
  height: ${props => (props.small ? "1.4rem" : "3rem")};
  @media ${ScreenWidthMediaQueries.tablet} {
    height: 0px;
  }
`

export const SpacerWithLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.backgroundLight};
  margin: 3rem 0;
  @media ${ScreenWidthMediaQueries.tablet} {
    margin: 70px 0;
  }
`

export const CenterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactLinkSection = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const ContactLink = styled.button`
  padding: 16px 28px;
  border-radius: 6px;
  margin: -1.4rem 0 3rem 0;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.headerBackground};
  cursor: pointer;
  box-shadow: 12px 12px 5px ${props => props.theme.colors.backgroundDark + "80"};
  & :hover {
    background-color: ${props => props.theme.colors.highlight};
  }
  & > h3 {
    margin-bottom: 0;
  }
`

const ContactLinkIcon = styled.span`
  font-size: 16px;
  display: inline;
`

const IndexPage = () => {
  return (
    <Layout isHeaderTransparent={true} section="home">
      <SEO title="Full Stack Developer" />

      <ImageSection className="r" data-rellax-speed="9">
        <BackgroundImage overlayColor="rgba(0, 0, 0, 0.5)" />
      </ImageSection>
      <ImageSectionOffset />
      <BodyContainer noVerticalPadding>
        <Spacer small />
        <ImageContent>
          <ContentCard dark>
            <h1>
              I'll grow your business online by building you an amazing website
            </h1>
            <h2>
              As a full stack <Highlight>Web Developer</Highlight> I've been
              building software at Facebook for over 7 years. Do you have any
              projects you need help with?
            </h2>
          </ContentCard>
        </ImageContent>
        <Spacer />
        <ColumnLayout flexDirection="row-reverse">
          <Column flex={2}>
            <ContactLinkSection>
              <ContactLink
                onClick={() => {
                  document
                    .getElementById("get_in_touch")
                    ?.scrollIntoView({ behavior: "smooth" })
                }}
              >
                <h3>Get In Touch</h3>
              </ContactLink>
            </ContactLinkSection>
          </Column>
          <Column flex={3}>
            <h1>What I can do for you</h1>
            <PitchItem>
              <IconContainer size={36}>
                <img src={TrendingUpIconURI} height={36} />
              </IconContainer>
              <PitchText>
                <Highlight>Increase your sales</Highlight> by building you an{" "}
                <Highlight>effective website</Highlight>
              </PitchText>
            </PitchItem>
            <PitchItem>
              <IconContainer size={36}>
                <img src={GroupAddIconURI} height={36} />
              </IconContainer>
              <PitchText>
                Drive <Highlight>more visitors</Highlight> to your site by
                improving your <Highlight>SEO</Highlight>
              </PitchText>
            </PitchItem>
            <PitchItem>
              <IconContainer size={36}>
                <img src={BuildIconURI} height={34} />
              </IconContainer>
              <PitchText>
                Build a <Highlight>complex feature</Highlight> for your web
                application
              </PitchText>
            </PitchItem>
            <PitchItem>
              <IconContainer size={36}>
                <img src={MobileFriendlyIconURI} height={36} />
              </IconContainer>
              <PitchText>
                Make your site look good on <Highlight>mobile</Highlight>
              </PitchText>
            </PitchItem>
          </Column>
        </ColumnLayout>
        <SpacerWithLine />
        <MyWorkSection />
        <SpacerWithLine />
        <CenterSection>
          <h2>I love building with</h2>
          <SkillBlockContainer>
            <SkillBlock icon="React" />
            <SkillBlock icon="Javascript" />
            <SkillBlock icon="Typescript" />
            <SkillBlock icon="GraphQL" />
            <SkillBlock icon="Relay" />
            <SkillBlock icon="Hack" />
            <SkillBlock icon="NodeJS" />
            <SkillBlock icon="Gatsby" />
            <SkillBlock icon="Firebase" />
          </SkillBlockContainer>
        </CenterSection>
        <Spacer />
        <CenterSection>
          <h2>I'm also good at</h2>
          <SkillBlockContainer>
            <SkillBlock icon="PHP" />
            <SkillBlock icon="Wordpress" />
            <SkillBlock icon="Apollo" />
            <SkillBlock icon="Flow" />
            <SkillBlock icon="HTML" />
            <SkillBlock icon="CSS" />
          </SkillBlockContainer>
        </CenterSection>
      </BodyContainer>
    </Layout>
  )
}

export default IndexPage
