import { useState } from "react"

export default function useIsWebpSupported(): boolean | undefined {
  const [isSupported, setIsSupported] = useState<boolean>()
  if (typeof window === "undefined") {
    return undefined
  }
  const img = new Image()
  img.onload = function() {
    const result = img.width > 0 && img.height > 0
    setIsSupported(result)
  }
  img.onerror = function() {
    setIsSupported(false)
  }
  img.src =
    "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA"
  return isSupported
}
