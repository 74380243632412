import React from "react"

import ScreenWidthMediaQueries from "./screen-width-media-queries"

import styled from "styled-components"

interface Props {
  children: React.ReactChild | React.ReactChild[]
  noVerticalPadding?: boolean
}

const BodySection = styled.div<Props>`
  margin: 0 auto;
  max-width: 1024px;
  padding: ${props =>
    props.noVerticalPadding ? "0 16px" : "16px 16px 0 16px"};

  @media ${ScreenWidthMediaQueries.tablet} {
    padding: ${props =>
      props.noVerticalPadding ? "0 40px" : " 40px 40px 0 40px"};
  }
`

const BodyContainer = (props: Props): React.ReactElement => {
  const { children } = props
  return <BodySection {...props}>{children}</BodySection>
}

export default BodyContainer
