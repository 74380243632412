import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ScreenWidthMediaQueries from "./common/screen-width-media-queries"
import styled from "styled-components"
import Highlight from "./common/highlight"
import BodyContainer from "./common/body-container"

const FixedHeader = styled.header<{ isTransparent?: boolean }>`
  background-color: ${props =>
    props.isTransparent ? "#00000000" : props.theme.colors.headerBackground};
  position: ${props => (props.isTransparent ? "absolute" : "static")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
`

const TopBanner = styled.div`
  background-color: ${props => props.theme.colors.backgroundDark};
  color: ${props => props.theme.colors.primary};
  justify-content: center;
  align-items: center;
  display: flex;

  font-size: 0.8rem;
`

const Nav = styled.nav`
  height: 70px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`

const StyledHeaderLink = styled(({ isCurrentPage: _, ...rest }) => (
  <Link {...rest} />
))`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin: ${props => props.margin || 0};
  border-bottom: 2px solid
    ${props =>
      props.isCurrentPage
        ? props.theme.colors.primary
        : props.theme.colors.headerBackground};
  :hover {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }
  transition: border-color 0.1s ease-in;
  font-weight: 400;
`

const HeaderRightSide = styled.nav`
  height: 100%;
  align-items: center;
  display: flex;
`

interface Props {
  isTransparent?: boolean
  section?: "home" | "about" | "blog"
}

const Header = ({ isTransparent, section }: Props): React.ReactElement => (
  <>
    <FixedHeader isTransparent={isTransparent}>
      <TopBanner>
        <BodyContainer noVerticalPadding>
          <Highlight>Special Offer</Highlight>: Free website in return for
          featuring your site!
        </BodyContainer>
      </TopBanner>
      <BodyContainer noVerticalPadding>
        <Nav>
          <StyledHeaderLink to="/" isCurrentPage={section === "home"}>
            Mark Duppenthaler
          </StyledHeaderLink>
          <HeaderRightSide>
            <StyledHeaderLink
              to="/about"
              margin="0 32px 0 0"
              isCurrentPage={section === "about"}
            >
              About
            </StyledHeaderLink>
            <StyledHeaderLink to="/blog" isCurrentPage={section === "blog"}>
              Blog
            </StyledHeaderLink>
          </HeaderRightSide>
        </Nav>
      </BodyContainer>
    </FixedHeader>
  </>
)

export default Header
