import React from "react"
import styled from "styled-components"

import ReactLogoURL from "../images/logos/react.svg"
import RelayLogoURL from "../images/logos/relay.svg"
import GraphQLLogoURL from "../images/logos/graphql.svg"
import PHPLogoURL from "../images/logos/php.svg"
import HackLogoURL from "../images/logos/hack.svg"
import ApolloLogoURL from "../images/logos/apollostack.svg"
import JavascriptLogoURL from "../images/logos/javascript.svg"
import TypescriptLogoURL from "../images/logos/typescript-icon.svg"
import WordpressLogoURL from "../images/logos/wordpress-icon.svg"
import FirebaseLogoURL from "../images/logos/firebase.svg"
import NodeJSLogoURL from "../images/logos/nodejs-icon.svg"
import GatsbyLogoURL from "../images/logos/gatsby.svg"
import FlowLogoURL from "../images/logos/flow.svg"
import HTMLLogoURL from "../images/logos/html-5.svg"
import CSSLogoURL from "../images/logos/css-3.svg"
import JavaLogoURL from "../images/logos/java.svg"
import CPPLogoURL from "../images/logos/c-plusplus.svg"
import MySQLLogoURL from "../images/logos/mysql.svg"
import MongoDBLogoURL from "../images/logos/mongodb-icon.svg"
import PythonLogoURL from "../images/logos/python.svg"
import DjangoLogoURL from "../images/logos/django.svg"
import FlaskLogoURL from "../images/logos/flask.svg"
import FigmaLogoURL from "../images/logos/figma.svg"
import SketchLogoURL from "../images/logos/sketch.svg"
import AndroidLogoURL from "../images/logos/android-icon.svg"
import IOSLogoURL from "../images/logos/ios.svg"

import ScreenWidthMediaQueries from "./common/screen-width-media-queries"

const SkillBlockItem = styled.div`
  width: 120px;
  @media ${ScreenWidthMediaQueries.tablet} {
    width: 150px;
  }
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4px 0;
`

const SkillBlockTitle = styled.p`
  margin-top: 8px;
`

const IconMap = {
  React: ReactLogoURL,
  GraphQL: GraphQLLogoURL,
  Relay: RelayLogoURL,
  Apollo: ApolloLogoURL,
  PHP: PHPLogoURL,
  Hack: HackLogoURL,
  Javascript: JavascriptLogoURL,
  Typescript: TypescriptLogoURL,
  NodeJS: NodeJSLogoURL,
  Gatsby: GatsbyLogoURL,
  HTML: HTMLLogoURL,
  CSS: CSSLogoURL,
  Wordpress: WordpressLogoURL,
  Firebase: FirebaseLogoURL,
  Java: JavaLogoURL,
  "C++": CPPLogoURL,
  Flow: FlowLogoURL,
  MySQL: MySQLLogoURL,
  MongoDB: MongoDBLogoURL,
  Django: DjangoLogoURL,
  Python: PythonLogoURL,
  Flask: FlaskLogoURL,
  Figma: FigmaLogoURL,
  Sketch: SketchLogoURL,
  Android: AndroidLogoURL,
  IOS: IOSLogoURL,
}

const LogoImage = styled.img<{ invert?: boolean }>`
  filter: ${props =>
    props.invert ? "invert(100%) hue-rotate(180deg)" : "none"};
  width: 50;
  height: 50;
  margin-bottom: 0px;
`

const InvertIcons = new Set(["Apollo", "Wordpress", "MySQL", "IOS", "Flask"])

function SkillBlock({
  icon,
}: {
  icon: keyof typeof IconMap
}): React.ReactElement {
  const props = { src: IconMap[icon], width: 50, height: 50 }
  return (
    <SkillBlockItem>
      <div>
        <LogoImage {...props} invert={InvertIcons.has(icon)} />
      </div>
      <SkillBlockTitle>{icon}</SkillBlockTitle>
    </SkillBlockItem>
  )
}

export default SkillBlock
