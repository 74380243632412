import React from "react"

import ApiUpgradeToolImage from "../components/api-upgrade-tool-image"
import BlogImage from "../components/blog-image"
import AnonymousLoginImage from "../components/anonymous-login-image"

import ScreenWidthMediaQueries from "../components/common/screen-width-media-queries"
import styled from "styled-components"
import { CenterSection, Spacer, SpacerWithLine } from "../pages"
import Highlight from "./common/highlight"
import ColumnLayout, { Column } from "./common/column-layout"

const ImageSection = styled.div<{
  justifyContent?: "flex-start" | "flex-end" | "center"
}>`
  display: flex;
  justify-content: center;

  @media ${ScreenWidthMediaQueries.tablet} {
    justify-content: ${props => props.justifyContent || "center"};
  }
`

const ImageContainer = styled.div<{
  align: "left" | "center" | "right"
  maxWidth: number
}>`
  width: 100%;
  max-width: ${props => props.maxWidth}px;
`

const Tag = styled.span`
  padding-right: 8px;
`

interface TextSectionProps {
  isOnRight?: boolean
}
export const TextSection = styled.div<TextSectionProps>`
  padding: 0px;
  @media ${ScreenWidthMediaQueries.tablet} {
    padding-left: ${props => (props.isOnRight ? "36px" : 0)};
    padding-right: ${props => (props.isOnRight ? 0 : "36px")};
  }
`

export const Paragraph = styled.p`
  @media ${ScreenWidthMediaQueries.tablet} {
    margin-bottom: 0;
  }
`

function TagHighlight({ children }: { children: React.ReactChild }) {
  return (
    <Tag>
      <Highlight size="small">{children}</Highlight>
    </Tag>
  )
}

export default function MyWorkSection(): React.ReactElement {
  return (
    <>
      <CenterSection>
        <h2>Selected Work</h2>
      </CenterSection>
      <Spacer />
      <ColumnLayout flexDirection="row-reverse">
        <Column flex={3}>
          <TextSection isOnRight>
            <h2>This Site</h2>
            <p>
              <TagHighlight>React</TagHighlight>
              <TagHighlight>Styled Components</TagHighlight>
              <TagHighlight>Typescript</TagHighlight>
              <TagHighlight>GatsbyJS</TagHighlight>
            </p>
            <Paragraph>
              Designed and built using modern technologies I love! It's blazing
              fast with minimal dependencies. I write the blog in markdown and
              generate the site statically so it could be hosted for free on
              netlify.
            </Paragraph>
          </TextSection>
        </Column>
        <Column flex={2}>
          <ImageSection>
            <ImageContainer align="right" maxWidth={400}>
              <BlogImage />
            </ImageContainer>
          </ImageSection>
        </Column>
      </ColumnLayout>
      <SpacerWithLine />
      <ColumnLayout>
        <Column flex={3}>
          <TextSection>
            <h2>Facebook API Upgrade Tool</h2>
            <p>
              <TagHighlight>React</TagHighlight>
              <TagHighlight>Hack (PHP)</TagHighlight>
              <TagHighlight>MySQL</TagHighlight>
            </p>
            <Paragraph>
              I built the{" "}
              <a
                href="https://developers.facebook.com/tools/api_versioning/"
                target="_blank"
                rel="noreferrer"
              >
                official tool from Facebook
              </a>{" "}
              for helping developers upgrade their apps to new versions of the
              Graph API. It's personalized for each developer from real calls
              coming from their app. It shows which of them may break with an
              upcoming API change. I got a{" "}
              <a
                href="https://patents.google.com/patent/US10310850B2"
                target="_blank"
                rel="noreferrer"
              >
                patent
              </a>{" "}
              for this too.
            </Paragraph>
          </TextSection>
        </Column>
        <Column flex={2}>
          <ImageSection>
            <ImageContainer align="left" maxWidth={400}>
              <ApiUpgradeToolImage />
            </ImageContainer>
          </ImageSection>
        </Column>
      </ColumnLayout>
      <SpacerWithLine />
      <ColumnLayout flexDirection="row-reverse">
        <Column flex={3}>
          <TextSection isOnRight>
            <h2>Facebook Anonymous Login</h2>
            <p>
              <TagHighlight>Javascript</TagHighlight>
              <TagHighlight>Hack (PHP)</TagHighlight>
            </p>
            <Paragraph>
              This product made some headlines when it was{" "}
              <a
                href="https://techcrunch.com/2014/04/30/facebook-anonymous-login/"
                target="_blank"
                rel="noreferrer"
              >
                announced at F8 2014
              </a>{" "}
              but it never ended up launching widely. It was funny that a photo
              of my hand made it to the{" "}
              <a
                href="https://www.sfgate.com/business/slideshow/Scenes-from-Facebook-s-F8-conference-84926/photo-6240187.php"
                target="_blank"
                rel="noreferrer"
              >
                local press
              </a>{" "}
              when I was demoing the product. I even got my first{" "}
              <a
                href="https://patents.google.com/patent/US20160065541"
                target="_blank"
                rel="noreferrer"
              >
                patent
              </a>{" "}
              for it.
            </Paragraph>
          </TextSection>
        </Column>
        <Column flex={2}>
          <ImageSection justifyContent="center">
            <ImageContainer align="center" maxWidth={240}>
              <AnonymousLoginImage />
            </ImageContainer>
          </ImageSection>
        </Column>
      </ColumnLayout>
    </>
  )
}
