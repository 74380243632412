import React from "react"
import styled from "styled-components"
import { CenterSection, Spacer } from "../../pages"
import ContactBlock from "./contact-block"
import BodyContainer from "./body-container"
import ScreenWidthMediaQueries from "./screen-width-media-queries"

const FooterSection = styled.footer`
  background-color: ${props => props.theme.colors.backgroundDark};
  position: relative;
`

const DiagonalDivider = styled.div`
  width: vw;
  height: 20px;
  @media ${ScreenWidthMediaQueries.tablet} {
    height: 50px;
  }
  background-color: #242424;
  clip-path: polygon(0 -1%, 100% -1%, 50% 100%);
  position: relative;
  left: 0;
  z-index: 2;
`

export default function Footer(): React.ReactElement {
  return (
    <FooterSection>
      <DiagonalDivider />

      <BodyContainer>
        <CenterSection>
          <ContactBlock />
        </CenterSection>
      </BodyContainer>

      <Spacer />
    </FooterSection>
  )
}
