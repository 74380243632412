/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"

import GlobalStyles from "./common/global-styles"

import styled, { ThemeProvider } from "styled-components"
import Theme from "./common/styled-components-theme"
import Footer from "./common/footer"

const FlexPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyWrapper = styled.main`
  flex: "1 0 0";
  background-color: #242424;
`
interface Props {
  children: React.ReactNode
  isHeaderTransparent?: boolean
  section?: "home" | "about" | "blog"
}

const Layout = ({
  children,
  isHeaderTransparent,
  section,
}: Props): React.ReactElement => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <FlexPage>
        <Header isTransparent={isHeaderTransparent} section={section} />
        <BodyWrapper>{children}</BodyWrapper>
        <Footer />
      </FlexPage>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
