import React from "react"
import styled from "styled-components"

import ScreenWidthMediaQueries, {
  ScreenWidths,
} from "./screen-width-media-queries"

const Layout = styled.div<Props>`
  display: block;
  @media ${ScreenWidthMediaQueries.tablet} {
    display: flex;
  }
  flex-direction: ${props => props.flexDirection ?? "row"};
`

interface ColumnProps {
  flex: number
  children: React.ReactChild | React.ReactChild[]
}

const ColumnContainer = styled.div<ColumnProps>`
  flex: ${props => props.flex} ${props => props.flex} 0;
`

export function Column(props: ColumnProps): React.ReactElement {
  return <ColumnContainer {...props} />
}

interface Props {
  children: React.ReactChild | React.ReactChild[]
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse"
}
export default function ColumnLayout(props: Props): React.ReactElement {
  return <Layout {...props} />
}
