export interface ThemeProps {
  theme: {
    colors: {
      primary: string
      background: string
      backgroundLight: string
      backgroundDark: string
      headerBackground: string
      highlight: string
    }
  }
}

const Theme = {
  colors: {
    primary: "#FFFFFFD0",
    background: "#242424",
    backgroundLight: "#3B3B3B",
    backgroundDark: "#0f0f0f",
    errorInput: "#FF8A8A",
    headerBackground: "#3F66B9",
    highlight: "#3F66B9B0",
  },
}

export const InvertedTheme = {
  colors: {
    primary: "#000",
    background: "#dbdbdb",
    headerBackground: "#3F66B9",
    highlight: "#3F66B9D0",
  },
}

export default Theme
