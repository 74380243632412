import React from "react"
import styled, { keyframes } from "styled-components"

const ContainerKeyFrames = keyframes`
  100% { transform: rotate(360deg); } 
`

const ChaseDotKeyFrames = keyframes`
  80%, 100% { transform: rotate(360deg); } 
`

const ChaseDotBeforeKeyFrames = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
`

const SpinnerContainer = styled.div<Props>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  position: relative;
  animation: ${ContainerKeyFrames} 2.5s infinite linear both;
`

const ChaseDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${ChaseDotKeyFrames} 2s infinite ease-in-out both;
  &:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: ${ChaseDotBeforeKeyFrames} 2s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
  }
  &:nth-child(2) {
    animation-delay: -1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.8s;
  }
  &:nth-child(5) {
    animation-delay: -0.7s;
  }
  &:nth-child(6) {
    animation-delay: -0.6s;
  }
  &:nth-child(1):before {
    animation-delay: -1.1s;
  }
  &:nth-child(2):before {
    animation-delay: -1s;
  }
  &:nth-child(3):before {
    animation-delay: -0.9s;
  }
  &:nth-child(4):before {
    animation-delay: -0.8s;
  }
  &:nth-child(5):before {
    animation-delay: -0.7s;
  }
  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`

// based on https://github.com/tobiasahlin/SpinKit
interface Props {
  size: number
}
function SimpleSpinner(props: Props): React.ReactElement {
  return (
    <SpinnerContainer {...props}>
      <ChaseDot />
      <ChaseDot />
      <ChaseDot />
      <ChaseDot />
      <ChaseDot />
      <ChaseDot />
    </SpinnerContainer>
  )
}

export default SimpleSpinner
